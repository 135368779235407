import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import MultiSelectElective, {
  ModalSelectElectiveProps as MultiSelectElectiveProps,
} from '@components/Modal/ModalSelectElective'
import { ElectiveOption } from '@components/Modal/ModalSelectElective/interface'
import SingleSelectElective, {
  ModalSelectElectiveProps as SingleSelectElectiveProps,
} from '@berlitzplatforms/micro.modal.select-elective/lib'
import PortalLearningPath from '@classes/LearningPath'
import useEnsureNoDisplayedModal from '@hooks/useEnsureNoDisplayedModal'
import { ElectivesQueryResult } from '@interfaces/Electives'
import { GET_ELECTIVES } from '@queries/electives'
import { PUT_REGISTRATION_MATERIALS } from '@queries/registrations'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingModal from '../LoadingModal'
import WarningModal from '../WarningModal'
import { registrationSelector } from '@components/ActiveState/activeStateSlice'
import { useSelector } from 'react-redux'

export interface SelectElectiveModalProps {
  MainMaterialId: string
  StudentProfileId: string
  Path: PortalLearningPath
  autoOpen?: boolean
  open?: boolean
  onClose?: () => void
}

export const SelectElectiveModal: React.FC<SelectElectiveModalProps> = ({
  MainMaterialId,
  StudentProfileId,
  Path,
  autoOpen,
  open: manualOpen,
  onClose = () => {},
}) => {
  const intl = useIntl()
  const [open, setOpen] = useState(!!manualOpen)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<ElectiveOption[]>()
  const [defaultVal, setDefaultVal] = useState<string>()
  const [error, setError] = useState(false)
  const showMulti = useSelector(registrationSelector.selectIsElectiveMulti)

  const [putElective, { loading: loadingPut, error: putError }] = useMutation(PUT_REGISTRATION_MATERIALS, {
    onError: () => {},
    onCompleted: async () => {
      location.reload()
    },
  })

  const [getElectives, { data: electives, loading: loadingE, error: eError }] = useLazyQuery<ElectivesQueryResult>(
    GET_ELECTIVES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (MainMaterialId) {
      getElectives({
        variables: {
          MainMaterialId,
          StudentProfileId,
        },
      })
    }
  }, [MainMaterialId])

  useEffect(() => {
    setError(!!putError || !!eError)
  }, [putError, eError])

  useEnsureNoDisplayedModal(
    () => {
      if (electives?.getElectives?.mainAndElectiveMaterialInfo?.electiveMaterials?.length) {
        const opts = electives?.getElectives?.mainAndElectiveMaterialInfo?.electiveMaterials?.map((opt) => ({
          value: opt.electiveMaterialId,
          label: opt.electiveMaterialName,
          key: opt.electiveMaterialId,
          selected: false,
        }))
        setDefaultVal(opts[0].value)
        setOptions(opts as ElectiveOption[])
      }
    },
    [electives],
    autoOpen && !!localStorage.getItem(Path.tempFlags.SelectElective)
  )

  useEffect(() => {
    if (options?.length) {
      const tempFlag = localStorage.getItem(Path?.tempFlags?.SelectElective)
      const shouldOpen = !!autoOpen && !tempFlag
      setOpen(shouldOpen)

      if (shouldOpen) {
        localStorage.setItem(Path.tempFlags.SelectElective, 'true')
      }
    }
  }, [JSON.stringify(options)])

  useEffect(() => {
    typeof manualOpen === 'boolean' && setOpen(manualOpen && !!options?.length)
  }, [manualOpen, JSON.stringify(options)])

  useEffect(() => {
    setLoading(loadingE && !!manualOpen && !open)
  }, [loadingE, manualOpen, open])

  const multiOnButtonClick = (electives: ElectiveOption[]) => {
    setOpen(false)
    onClose()
    const registrationMaterialInput = electives?.map((elective) => ({
      RegistrationMaterial: {
        RegistrationId: Path.Material?.RegistrationId,
        MaterialId: elective?.value,
      },
    }))

    putElective({
      variables: {
        registrationMaterialInput,
      },
    })
  }

  const singleOnButtonClick = (selected: any) => {
    setOpen(false)
    onClose()
    putElective({
      variables: {
        registrationMaterialInput: [
          {
            RegistrationMaterial: {
              RegistrationId: Path.Material?.RegistrationId,
              MaterialId: selected,
            },
          },
        ],
      },
    })
  }

  const props = {
    open: open,
    options,
    onClose: () => {
      setOpen(false)
      onClose()
    },
    title: (
      <FormattedMessage
        id="Welcome to {name}!"
        defaultMessage="Welcome to {name}!"
        values={{ name: Path?.Material?.MaterialName }}
      />
    ),
    description: (
      <FormattedMessage
        id="Before you start your level please select the elective course you'd like to take."
        defaultMessage="Before you start your level please select the elective course you'd like to take."
      />
    ),
    buttonText: <FormattedMessage id="Begin your course" defaultMessage="Begin your course" />,
    onButtonClick: singleOnButtonClick, //
    selectLabel: intl.formatMessage({ id: 'Select an elective' }),
  }

  const MemoModal = useMemo(() => {
    if (defaultVal) {
      if (showMulti) {
        const modifiedProps: MultiSelectElectiveProps = {
          ...props,
          maxLimit: 3,
          onButtonClick: multiOnButtonClick,
        }
        return <MultiSelectElective {...modifiedProps} />
      } else {
        const modifiedProps: SingleSelectElectiveProps = {
          ...props,
        }
        return <SingleSelectElective {...modifiedProps} />
      }
    } else {
      return null
    }
  }, [JSON.stringify(options), Path?.Id, defaultVal, open])

  return (
    <>
      {MemoModal}
      <LoadingModal
        open={loading || loadingPut}
        onClose={() => {}}
        title={`${intl.formatMessage({
          id: loadingPut || error ? 'Updating course...' : 'Fetching electives...',
        })}`}
        subtitle={`${intl.formatMessage({ id: 'Please wait a moment' })}.`}
      />
      <WarningModal open={error} onClose={() => setError(false)} anApiError noButtons />
    </>
  )
}
