import React, { useState } from 'react'
import {
  StyledModalSelectElective,
  Button,
  H1,
  Content,
  SubLabel,
  SelectWrapper,
  StyledMultiSelect,
  Label,
  FullWidth,
} from './style'
import { ElectiveOption, ModalSelectElectiveProps } from './interface'
import Spacer from '@berlitz/spacer'
import Modal from '@berlitz/modal'
import ImageWelcome from '@berlitzplatforms/micro.image.welcome'
import Select from 'react-select'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col } from 'react-styled-flexboxgrid'
import SelectedItem from './SelectedItem'
import isNumber from 'lodash/isNumber'
import { registrationSelector } from '@components/ActiveState/activeStateSlice'
import { useSelector } from 'react-redux'

const ModalSelectElective: React.FC<ModalSelectElectiveProps> = ({
  id: cId,
  sample,
  open,
  onClose = () => {},
  onButtonClick = () => {},
  onSelected = () => {},
  title,
  description = `Before you start your level please select the elective course you'd like to take.`,
  buttonText = `Begin your course`,
  options = [],
  selectLabel = 'Select an elective',
  maxLimit,
  ...rest
}) => {
  const [openModal, setOpenModal] = useState(open)

  const assignedElectives = useSelector(registrationSelector.selectElectiveMaterials) ?? []
  const opts: ElectiveOption[] = assignedElectives?.map((elective) => ({
    value: elective?.MaterialId,
    label: elective?.MaterialName,
    key: elective?.MaterialId,
    selected: true,
  }))
  const [electives, setElectives] = useState<ElectiveOption[]>(
    [...options, ...opts]?.filter(({ selected }) => selected)
  )
  const [id] = useState(cId || `${new Date().getTime()}-select-elective-modal`)
  const intl = useIntl()

  const closeModal = () => {
    setOpenModal(false)
    onClose()
  }

  const selectedElectives = electives.filter(({ selected }) => !selected)

  return (
    <>
      {sample && (
        <Button
          onClick={() => {
            setOpenModal(!openModal)
          }}
        >
          Open modal
        </Button>
      )}

      <Modal open={open || openModal} onClose={closeModal} {...rest}>
        <StyledModalSelectElective>
          <>
            <ImageWelcome width={'321'} height={'237'} />
            <Spacer size="lg" />
            <Spacer size="xxxs" />
            <H1>{title}</H1>
            <Spacer size="xxxs" />
            <Content>{description}</Content>
            <Spacer size="md" />
            <Spacer size="xxxs" />
            <SelectWrapper>
              <SubLabel>{selectLabel}</SubLabel>
              <Spacer size="xxs" />
              <StyledMultiSelect>
                <Select
                  id={id}
                  options={options}
                  menuPlacement="top"
                  components={{ IndicatorSeparator: () => null }}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      backgroundColor: 'white',
                      color: '#1D1D1D',
                      cursor: 'pointer',
                      fontWeight: 500,
                      fontSize: 14,
                      ':hover': {
                        backgroundColor: '#004FEF',
                        color: '#FFF',
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: '1px solid #B4B4B4',
                      borderRadius: '2px',
                      boxShadow:
                        '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      maxHeight: '36px',
                      minHeight: '36px',
                    }),
                    container: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      maxHeight: '36px',
                      minHeight: '36px',
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontFamily:
                        '"CircularPro", "Gill Sans", "Gill Sans MT", "Helvetica Neue", Helvetica, Arial, sans-serif',
                    }),
                  }}
                  onChange={(item) => {
                    /**
                     * Lets check if item does not yet exist in electives
                     */
                    if (item && !electives.find(({ value }) => value === item?.value)) {
                      /**
                       * - if maxLimit is not undefined then let check if the length of electives is less than maxLimit
                       * - if maxLimit is undefined then lets always append elective
                       */
                      const lessThanMaxLimit = isNumber(maxLimit) && electives.length < maxLimit
                      if (maxLimit !== undefined && lessThanMaxLimit) {
                        setElectives([...electives, item])
                      }

                      if (maxLimit === undefined) {
                        setElectives([...electives, item])
                      }
                    }
                  }}
                />
              </StyledMultiSelect>
            </SelectWrapper>
            <Spacer size="xxl" />
            <FullWidth>
              {electives.length > 0 && maxLimit !== undefined && (
                <Label>
                  <FormattedMessage
                    id="{electives}/{maxLimit} electives selected:"
                    defaultMessage="{electives}/{maxLimit} electives selected:"
                    values={{ maxLimit, electives: (electives || []).length }}
                  />
                </Label>
              )}
              <Row>
                {electives.map((elective) => (
                  <Col xs={12} md={12} key={`repeat-on-${elective.key}`} className="test">
                    <SelectedItem
                      name={elective.label}
                      smallXButton
                      closeHidden={elective?.selected}
                      onClose={() => {
                        setElectives(electives.filter(({ value }) => elective?.value !== value))
                      }}
                      errorTitle={intl.formatMessage({
                        id: 'Lesson not available',
                        defaultMessage: 'Lesson not available',
                      })}
                      errorMessage={intl.formatMessage({
                        id: 'Please remove and try another time',
                        defaultMessage: 'Please remove and try another time',
                      })}
                    />
                    <Spacer size="xs" />
                  </Col>
                ))}
              </Row>
            </FullWidth>
            <Spacer size="xxl" />
            <Button disabled={!Boolean(selectedElectives.length)} onClick={() => onButtonClick(selectedElectives)}>
              {buttonText}
            </Button>
            <Spacer size="xs" />
          </>
        </StyledModalSelectElective>
      </Modal>
    </>
  )
}

export default ModalSelectElective
