import styled, { css } from 'styled-components'
import BerlitzButton from '@berlitzplatforms/micro.ui.button'
import { H1 as BerlitzH1 } from '@berlitz/globals'
import { LabelProp } from './interface'

export const StyledMultiSelect = styled.div`
  ${({ theme }) => `
    width: 100%;

    input[type='text'] {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${theme.palette.charcoal};
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${theme.palette.charcoal};
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: ${theme.palette.charcoal};
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: ${theme.palette.charcoal};
      }
    }
  `}
`

export const StyledModalSelectElective = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => ` .ant-select-dropdown {
    border: solid 1px ${theme.palette.gray100};
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    color: ${theme.colors.brandSecondaryContrast};
  }

  .ant-select-dropdown-menu-item-selected {
    color: ${theme.colors.brandPrimary};
    font-weight: normal;
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
    background-color: ${theme.colors.brandPrimary};
    color: white;
    font-weight: bold;
  }

  .ant-select-dropdown-menu {
    margin-top: 0;
  }

  .ant-select-dropdown-menu-item-disabled:hover,
  .ant-select-dropdown-menu-item-disabled {
    background-color: ${theme.colors.brandPrimaryContrast};
    color: rgba(0, 0, 0, 0.25);
    font-weight: normal;
  }

  .ant-select-dropdown-menu-item-partial-disabled:hover,
  .ant-select-dropdown-menu-item-partial-disabled {
    background-color: ${theme.colors.brandPrimaryContrast};
    color: rgba(0, 0, 0, 0.25);
    font-weight: normal;
  }


  .ant-select-dropdown-menu-item.highlighted-option {
    color: ${theme.colors.brandPrimary};
  }

  .ant-select-dropdown-menu-item.highlighted-option:hover,
  .ant-select-dropdown-menu-item-active.highlighted-option {
    background-color: ${theme.colors.brandPrimary};
    color: ${theme.colors.brandPrimaryContrast};
    font-weight: bold;
  }

  .ant-empty {
    text-align: center;
  }

  .ant-empty .ant-empty-image img {
    margin: 0 auto;
  }`}
`

export const Button = styled(BerlitzButton)`
  ${({ theme }) => `
    padding: ${theme.space.xs} ${theme.space.md};
  `}
`

export const H1 = styled(BerlitzH1)`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.bold};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.12px;
    text-align: center;
    margin-bottom: ${theme.space.md};
  `}
`

export const Content = styled.div`
  text-align: center;
  max-width: 450px;
  font-size: ${({ theme }) => theme.fontSize.sm};
`

export const SubHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const SelectWrapper = styled.div`
  min-width: 65%;
  max-width: 100%;
`
export const SubLabel = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xxs};
    font-weight: ${theme.fontWeight.bold};
    line-height: 1.14;
    letter-spacing: 0.49px;
  `}
`

export const Label = styled.div<LabelProp>`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs};

  ${({ warning }) =>
    warning &&
    css`
      color: #d87515;
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.uiError};
      margin-top: ${({ theme }) => theme.space.xxs};

      span {
        svg {
          margin-left: 0;
          margin-right: ${({ theme }) => theme.space.xxs};
        }
      }
    `}

  span {
    svg {
      margin-left: ${({ theme }) => theme.space.xxs};
    }
  }
`

export const FullWidth = styled.div`
  width: 100%;
`
