import styled, { css } from 'styled-components'
import { ISmallXButton } from './interface'

export const Wrapper = styled.div<{ error?: boolean; disabled?: boolean; loading?: boolean }>`
  display: flex;
  flex-flow: column;
  ${({ theme, error, disabled, loading }) => css`
    border: 1px solid
      ${error && !disabled ? theme.palette.danger100 : loading ? theme.palette.blue30 : theme.palette.gray100};
    border-radius: ${theme.radius.sm};
    padding: ${theme.space.xs};
    line-height: 1.14;
    letter-spacing: 0.49px;
    font-size: ${theme.fontSize.xxs};
    background-color: ${error && !disabled
      ? theme.palette.danger10
      : loading
        ? theme.palette.blue10
        : theme.colors.brandSupporting01Contrast};
    color: ${disabled ? theme.palette.gray100 : loading ? theme.palette.blue50 : 'inherit'};

    ${CloseWrapper}, ${ErrorTitle} {
      color: ${disabled ? theme.palette.gray100 : theme.palette.danger100};
      svg {
        color: ${disabled ? theme.palette.gray100 : theme.palette.danger100};
      }
    }
  `}
`

export const Details = styled.div`
  display: flex;
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
`

export const Date = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `}
`

export const Error = styled.div`
  margin-top: ${({ theme }) => theme.space.sm};
`

export const ErrorTitle = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `}
`

export const CloseWrapper = styled.div<ISmallXButton>`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;

  ${({ theme, smallXButton }) => css`
    height: ${smallXButton ? '14px' : 'initial'};
    width: ${smallXButton ? '14px' : 'initial'};

    & > div {
      background-color: transparent;
      width: auto;
      height: auto;
      & > span {
        height: ${smallXButton ? '14px' : '20px'};
        width: ${smallXButton ? '14px' : '20px'};
        border-top: 3px solid ${theme.colors.brandPrimaryContrast};
        border-right: 3px solid ${theme.colors.brandPrimaryContrast};
        border-bottom: 3px solid ${theme.colors.brandPrimaryContrast};
      }
    }

    svg {
      font-size: ${smallXButton ? '14px' : '20px'};
      cursor: pointer;
      user-select: none;
    }
  `}
`
