import React from 'react'
import { CloseWrapper, Content, Date, Details, Error, ErrorTitle, Wrapper } from './style'
import { SelectedItemProps } from './interface'
import Close from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/33-Form-Validation/Close'
import Spinner from '@berlitz/spinner'

const noop = () => {}
const SelectedItem: React.FC<SelectedItemProps> = ({
  data,
  name,
  loading,
  disabled,
  closeHidden,
  onClose = noop,
  smallXButton,
}) => {
  return (
    <Wrapper>
      <Details>
        <Content>
          <div>{name}</div>
        </Content>
        {!closeHidden && (
          <CloseWrapper onClick={() => !disabled && !loading && onClose(data)} smallXButton={smallXButton}>
            {<Close />}
          </CloseWrapper>
        )}
      </Details>
    </Wrapper>
  )
}

export default SelectedItem
